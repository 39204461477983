footer {
    position: fixed;
    bottom: 0%;
    width: 100%;
}

.App {
    text-align: center;
}

.iconText {
    margin: 0 0.5rem;
}

.navbarStyle {
    padding: 0.5rem 1rem !important;
}

.navbarButton {
    color: #fff !important;
    padding: 0.5rem 1rem !important;
}

.iconText {
    margin: 0 0.5rem;
}

.navbarStyle {
    padding: 0.5rem 1rem;
}

.navbarButton {
    color: #fff !important;
}

.data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.todo-form {
    width: 60%;
}

.todo-list {
    width: 60%;
}

.todo-label {
    font-size: large;
    margin-right: 22%;
    margin-left: 3%;
}

.todo-view-btn {
    float: right;
}

td {
    word-break: break-word;
    max-width: 34rem;
}

.warningMessage {
    color: red;
}

.card-title {
    text-align: center;
}

.signInButton {
    margin: 1rem;
}

.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);

}

.search input {

    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;


}

.search input:focus {

    box-shadow: none;
    border: 2px solid blue;


}

.search .fa-search {

    position: absolute;
    top: 20px;
    left: 16px;

}

.search button {

    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue;

}